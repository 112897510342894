<template>
  <!-- NFT List -->
  <!-- <div>
    {{myprofile}}
  </div> -->
  <div v-if="myprofile.role == 1">
    <div class="q-pa-md flex row justify-center">
      <!-- <q-btn color="orange" label="All" @click="all" />
      <q-btn color="orange" label="Todo" @click="todo" />
      <q-btn color="orange" label="Banned" @click="banned" />
      <q-btn color="orange" label="Deleted" @click="deleted" /> -->
    </div>
    <div class="q-pa-md row my-list">
      <div
        v-for="k in allnfts"
        :key="k.id"
        class="q-pa-md col-xs-12 col-sm-4 col-md-3 col-lg-3"
      >
        <q-card class="my-card">
                    <q-card-section @click="show_nft(k.id)">

          <q-img class="my-img q-pa-md" :ratio="1" :src="k.data.image" >
            <q-img
              v-if="k.total - k.token_sum <= 0"
              class="absolute-center"
              src="@/assets/soldout.png"
            />
          </q-img>
                    </q-card-section>
          <q-card-section>
            <div class="text-h6">{{ k.data.name }}</div>
            <div class="text-body2">
              {{ pdate(k.created_at) }}
            </div>
            <div class="text-body2">{{ $t("price") }}: ${{ k.data.price }}</div>
            <div class="text-body2">
              {{ $t("total") }}: {{ k.total }} {{ $t("remains") }}:
              {{ k.total - k.token_sum }}
            </div>
            <div class="text-body2">
              Likes: {{ k.likes }} Flag: {{ k.flag }}
            </div>
          </q-card-section>
          <q-card-section class="q-pt-none">
            <!-- <q-btn class="q-ma-sm" size="xs" label="View" @click="show_nft(k.id)" /> -->
            <q-btn round v-if="k.flag!=-9999" size="md" color="red" class="q-ma-xs " icon="delete" @click="ban(k)" />
            <q-btn
              round
              class="q-ma-xs float-right"
              color="blue"
              size="md" 
              icon="edit"
              @click="edit_nft(k.id)"
            />
            <q-btn round v-if="k.flag!=-8888" class="q-ma-xs" size="md" color="orange float-right" label="Hide" @click="hide(k)" />
            <q-btn round v-if="(k.flag!=0 && k.flag!=-100)" size="md" class="q-ma-xs float-right" color="green" label="List" @click="list(k)" />
          </q-card-section>
        </q-card>
      </div>
    </div>
    <div class="fit row wrap justify-center items-start content-start">
      <InfiniteLoading :comments="allnfts" @infinite="load" />
    </div>
  </div>

  <div v-else>
    <p>Access denied!</p>
  </div>
</template>
<style lang="sass" scoped>
.my-content
  cursor: pointer
  text-align: center
  padding: 12px
  background-color: rgba(0, 0, 0, 0.5)

.my-link
  display: inline-block
  width: 100%
  overflow: hidden
  text-overflow: ellipsis

.my-list
  margin-left: 1rem
  margin-right: 1rem

.myavatar
  position: absolute
  left: 50%
  margin-left: -100px
  bottom: -100px

.myname
  margin-top: 120px

.my-card
  cursor: pointer
</style>

<script>
import router from "@/router";
import { supabase as s } from "../supabase";
import { ref, inject, onBeforeMount, onMounted } from "vue";
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css"; //required if you're not going to override default slots

export default {
  name: "Audit",
  components: {
    InfiniteLoading,
  },
  setup(props) {
    let allnfts = ref([]);
    let page = 0;
    let page_size = 20;
    let load = async ($state) => {
      console.log("loading...");
      try {
        let morenfts = await s
          .from("v_nfts")
          .select("*")
          .range(page_size * page, page_size * (page + 1) - 1)
          .order("created_at", { ascending: false });

        if (morenfts.error) {
          console.log(morenfts.error);
        }
        const json = morenfts.data;
        if (json.length < 10) $state.complete();
        else {
          page++;
          allnfts.value.push(...json);
          $state.loaded();
        }
        // page++;
      } catch (error) {
        $state.error();
      }
    };

    // onBeforeMount(async () => {
    //   {
    //     let { data: nfts, error } = await s
    //       .from("v_nfts")
    //       .select("*")
    //       .limit(page_size)
    //       .order("created_at", { ascending: false });
    //     if (error) console.log(error);
    //     console.log(nfts);

    //     allnfts.value = nfts;
    //   }
    // });
    const profile = inject("profile");
    return {
      user: s.auth.user(),
      page,
      page_size,
      allnfts,
      load,
      q: inject("q"),
      myprofile:profile
    };
  },

  methods: {
    async edit_nft(id) {
      window.open("/edit/" + id);
    },    
    show_nft(id) {
      window.open("/nft/" + id);
    },
    pdate(dstr) {
      const date = new Date(dstr);
      return new Intl.DateTimeFormat("default", { dateStyle: "long" }).format(
        date
      );
    },
    async list(k) {
      k.flag = 0;
      let { data, error } = await s
        .from("nfts")
        .update({ flag: k.flag })
        .match({ id: k.id });
      if (error) console.log(error);
      else {
        this.q.notify("List NFT:" + k.id + " " + k.data.name);
        console.log(data);
      }
    },
    async hide(k) {
      k.flag = -9999;
      let { data, error } = await s
        .from("nfts")
        .update({ flag: k.flag })
        .match({ id: k.id });
      if (error) console.log(error);
      else {
        this.q.notify("List NFT:" + k.id + " " + k.data.name);
        console.log(data);
      }
    },
    async ban(k) {
      // update nft flag
      {
        k.flag = -8888;
        let { data, error } = await s
          .from("nfts")
          .update({ flag: k.flag })
          .match({ id: k.id });
        if (error) console.log(error);
        else {
          this.q.notify("Ban NFT:" + k.id);
          console.log(data);
        }
      }
      // delete image from storage
      let filename = k.data.image.substring(k.data.image.lastIndexOf("/") + 1);
      {
        const { data, error } = await s.storage
          .from("cnft")
          .remove(["nft/" + filename]);
        if (error) {
          this.q.notify(error);
          console.log(error);
        } else {
          this.q.notify("Delete file from storage: " + filename);
          console.log(data);
        }
      }
    },
  },
};
</script>
